<template>
  <div>
    <b-form @submit.prevent="submitCreate" enctype="multipart/form-data">
      <b-card no-body class="mb-1">
        <b-card-header>
          <b-card-title class="mb-0"> Dados </b-card-title>
        </b-card-header>
        <b-card-body class="pl-2 pr-2">
          <div class="form-row">
            <!-- <div class="col-md-3">
              <div class="form-group">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Tipo da Pessoa
                </label>
                <v-select :searchable="false" label="title" item-text="title" item-value="code" v-model="item.type"
                  :options="optionsTypeCompanies" :class="{ 'is-invalid': $v.item.type.$error }">
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  CNPJ/CPF
                </label>
                <b-input-group>
                  <input class="form-control" type="text" v-mask="['###.###.###-##', '##.###.###/####-##']"
                    v-model="item.document" :class="{ 'is-invalid': $v.item.document.$error }" />
                </b-input-group>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="">
                  Inscrição Estadual
                  <i class="bi bi-question-circle" v-b-tooltip.hover.top="'A inscrição estadual é o registro das empresas perante a Receita Estadual.'
                    "></i>
                </label>
                <input type="text" v-model="item.state_license" class="form-control" />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for=""> Inscrição Municipal </label>
                <input type="text" v-model="item.city_license" class="form-control" />
              </div>
            </div> -->
            <div class="col-md-5">
              <div class="form-group mb-md-0">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Nome
                </label>
                <input type="text" :class="{ 'is-invalid': $v.item.company_name.$error }" v-model="item.company_name"
                  class="form-control" />
              </div>
            </div>
 
            <!-- <div class="col-md-4">
              <div class="form-group">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Nome
                  <i class="bi bi-question-circle" v-b-tooltip.hover.top="'Nome registrado na junta comercial ou nome completo do contato.'
                    "></i>
                </label>
                <input type="text" :class="{ 'is-invalid': $v.item.trading_name.$error }" v-model="item.trading_name"
                  class="form-control" />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group mb-0">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Simples Nacional
                </label>
                <v-select label="title" :searchable="false" item-text="title" item-value="code"
                  v-model="item.simple_national" placeholder="Digite o nome" :options="[
                    { title: 'Sim', code: 1 },
                    { title: 'Não', code: 0 },
                  ]" :class="{ 'is-invalid': $v.item.simple_national.$error }">
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="">
                  Categoria
                </label>
                <v-select label="title" item-text="title" item-value="code" v-model="item.category"
                  placeholder="Digite o nome" :options="optionsCategories" :searchable="false">
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group mb-md-0">
                <label for=""> Abertura da empresa </label>
                <input type="text" placeholder="dd/mm/aaaa" v-model="item.date" v-mask="['##/##/####']"
                  class="form-control" />
              </div>
            </div> -->
            <div class="col-md-2">
              <div class="form-group mb-md-0">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Situação
                </label>
                <v-select label="title" item-text="title" item-value="code" :searchable="false" v-model="item.active"
                  :options="optionsStatus" :class="{ 'is-invalid': $v.item.active.$error }">
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
      
      <b-card class="card-header-custom-actions" header-tag="header">
        <template #header>
          <div class="form-row">
            <div class="col-md-11">
              <h4 class="mb-0">
                <i class="text-danger bi bi-record-circle"></i>
                Unidades de negócio
              </h4>
              <small class="mb-0">Selecione as unidades de negócio que farão parte desta Unidade estratégica.</small>
            </div>

            <div class="col-md-1">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary" @click="repeateAgainBusinessUnit" class="btn-block">
                <i class="bi bi-plus mr-25"></i>
              </b-button>
            </div>
          </div>
        </template>

        <b-card-text v-if="item.business_units.length" class="mt-0">
          <div class="form-row mb-1 align-items-center" v-for="(item, index) in item.business_units"
            :key="`business-units-key-${index}`">
            <div class="col-md-11">
              <div class="form-group mb-0">
                <v-select label="title" v-model="item.item" item-text="title" item-value="code"
                  placeholder="Unidade de negócio" :searchable="false" :options="optionsBusinessUnits" :class="{
                    'is-invalid':
                      $v.item.business_units.$error,
                  }">
                  <span slot="no-options">
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </div>
            </div>

            <div class="col-md-1">
              <div class="form-group mb-0">
                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" block
                  @click="removeStrategicUnit(index)">
                  <i class="bi bi-trash3"></i>
                </b-button>
              </div>
            </div>
          </div>
        </b-card-text>
        <b-card-text v-else class="mt-0">
          Nenhuma unidade de negócio cadastrada até o momento.
        </b-card-text>
      </b-card>

      <div class="form-row justify-content-end">
        <div class="col-md-2">
          <button type="submit" class="btn btn-block btn-success">
            <div v-if="submited">
              <b-spinner small variant="light" /> Verificando...
            </div>
            <div v-else>Cadastrar</div>
          </button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import {
  BCard,
  BSpinner,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BCardBody,
  BInputGroup,
  BFormInput,
  VBTooltip,
  BInputGroupAppend,
  BForm,
  BTab,
  BTabs,
  BButton,
  BCardText
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";

import BtnSaveAndBack from "@/components/button/SaveAndBack";

import { required } from "vuelidate/lib/validators";

import axios from "axios";

import vSelect from "vue-select";
import "swiper/css/swiper.css";

export default {
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  components: {
    BCard,
    BSpinner,
    BForm,
    BtnSaveAndBack,
    BCardHeader,
    BCardBody,
    BCardTitle,
    VBTooltip,
    BCardSubTitle,
    BFormCheckbox,
    vSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BTab,
    BTabs,
    BButton,
    BCardText
  },
  data() {
    return {
      docs: [],
      submited: false,
      item: {
        company_name: "",
        trading_name: "",
        category: "",
        type: "",
        simple_national: "",
        docs: [],
        business: true,
        date: "",
        email: "",
        state_license: "",
        city_license: "",
        phone: "",
        cellphone: "",
        whatsapp: "",
        address: "",
        complement: "",
        city: "",
        state: "",
        number: "",
        zipcode: "",
        neighborhood: "",
        active: "",
        note: "",
        business_units: []
      },
      optionsStatus: [
        {
          title: "Ativo",
          code: 1,
        },
        {
          title: "Inativo",
          code: 0,
        },
      ],
      optionsCategories: [],
      optionsTypeCompanies: [],
      optionsBusinessUnits: [],
      nextBusinessUnit: 0,
    };
  },
  methods: {
    removeBusinessUnit(index) {
      this.item.business_units.splice(index, 1);
    },
    repeateAgainBusinessUnit() {
      this.nextBusinessUnit++

      this.item.business_units.push({
        id: this.nextBusinessUnit,
      });
    },
    selectFiles() {
      this.$el.querySelector("#files").click();
    },
    deleteFile(event) {
      this.item.docs.splice(this.item.docs.indexOf(event), 1);
    },
    previewDocs(event) {
      const input = event.target;
      if (input.files) {
        const files = input.files;

        this.item.docs = files;
      }
    },
    async getCategories() {
      this.$store.dispatch("Client/forSelectCategories").then((res) => {
        this.optionsCategories = res;
      });
    },
    async getTypeCompanies() {
      this.$store.dispatch("Config/forSelectTypeCompanies").then((res) => {
        this.optionsTypeCompanies = res;
      });
    },
    searchCNPJ() {
      axios
        .get(
          `https://receitaws.com.br/v1/cnpj/${this.item.document.replace(
            /\D/g,
            ""
          )}`
        )
        .then((res) => {
          console.log(res);
        });
    },
    searchCep() {
      if (this.item.zipcode.length == 9) {
        axios
          .get(`https://viacep.com.br/ws/${this.item.zipcode}/json/`)
          .then((res) => {
            const data = res.data;

            this.item.address = data.logradouro;
            this.item.neighborhood = data.bairro;
            this.item.city = data.localidade;
            this.item.state = data.uf;
          })
          .catch((error) => console.log(error));
      }
    },
    submitCreate() {
      this.$v.$touch();
      this.submited = true;

      if (!this.$v.$error) {
        const formData = new FormData();

        formData.append("document", this.item.document ?? '');
        formData.append("company_name", this.item.company_name);
        formData.append("trading_name", this.item.trading_name);

        formData.append("email", this.item.email);
        formData.append("cellphone", this.item.cellphone);
        formData.append("phone", this.item.phone);
        formData.append("whatsapp", this.item.whatsapp);

        formData.append(
          "category",
          this.item.category ? this.item.category.code : ""
        );
        formData.append("type", this.item.type ? this.item.type.code : "");

        formData.append("state_license", this.item.state_license);
        formData.append("city_license", this.item.city_license);
        formData.append("date", this.item.date);
        formData.append("active",this.item.active.code);
        formData.append(
          "simple_national",
          this.item.simple_national ? this.item.simple_national.code : ""
        );

        formData.append("zipcode", this.item.zipcode);
        formData.append("number", this.item.number);
        formData.append("complement", this.item.complement);
        formData.append("address", this.item.address);
        formData.append("neighborhood", this.item.neighborhood);
        formData.append("city", this.item.city);
        formData.append("state", this.item.state);

        var files = this.item.docs;

        for (var index = 0; index < files.length; index++) {
          formData.append("docs[]", files[index]);
        }

        for (var index = 0; index < this.item.business_units.length; index++) {     
          formData.append("business_units[]", this.item.business_units[index].item.code);
        }

        formData.append("note", this.item.note);

        this.$store
          .dispatch("StrategicUnit/store", formData)
          .then(() => {
            this.notifyDefault("success");
            this.$router.push({ name: "strategic-units-list" });
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
  },
  validations: {
    item: {
      company_name: {
        required,
      },
      active: {
        required,
      },
      business_units: {
        required,
      }
    },
  },
  async mounted() {
    this.optionsBusinessUnits = await this.$store.dispatch("BusinessUnit/forSelect", '')
  }
};
</script>
